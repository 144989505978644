import usePage from 'hooks/usePage';
import styled from 'styled-components';
import { Form, Input, Button, Radio, Alert } from 'antd';
import Heading from 'components/Heading';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';

export default function Dashboard() {
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [msg, setMsg] = useState({
    status: false,
    message: '',
    type: ''
  })
  const [barcodeData, setBarcodeData] = useState(false);
  const [FileName, setFileName] = useState(false);
  const [layout, setLayout] = useState(1);
  const onChangeLayout = (e) => {
    setLayout(e.target.value);
  };
  const onFinish = (e) => {
    let file = e.target.files[0];
    setFileName(file.name);
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });
      var keys = data[10];
      var items = [];
      for (let i = 0; i < data.length; i++) {
        if (i > 10 && i < data.length - 1) {
          items.push(data[i]);
        }
      }
      var whole = [];
      for (let i = 0; i < items.length; i++) {
        let data = {};
        for (let j = 0; j < keys.length; j++) {
          if (keys[j] !== undefined && items[i][j] !== undefined) {
            data[`${keys[j]}`] = items[i][j];
          }
        }
        whole.push(data);
      }
      setBarcodeData(whole);
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  let myInterval

  const deleteRecord = async ({ recordId }) => {
    await axios.delete(`/delete-pdf-record/${recordId}`).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    })
  }

  const getPdfRecord = async ({ recordId }) => {
    await axios.get(`/get-pdf-record/${recordId}`).then((response) => {
      if (response.data.response.fields.Status === 'Done') {

        setLoading(false);
        clearInterval(myInterval)
        setMsg({
          status: true,
          message: response.data.msg,
          type: 'success'
        })
        setTimeout(() => {
          setMsg({
            status: false,
            message: '',
            type: ''
          })

          window.open(
            response?.data?.response?.fields?.File[0]?.url
          );

          deleteRecord({ recordId })

        }, 3000);
      } else if (response.data.response.fields.Status === 'Error') {
        clearInterval(myInterval)
        setMsg({
          status: true,
          message: 'Error while generating barcodes.',
          type: 'error'
        })
        setLoading(false);

        setTimeout(() => {
          setMsg({
            status: false,
            message: '',
            type: ''
          })
        }, 3000);
      }
    }).catch((error) => {
      setLoading(false);
      setMsg({
        status: true,
        message: error.message,
        type: 'error'
      })
      setTimeout(() => {
        setMsg({
          status: false,
          message: '',
          type: ''
        })
      }, 3000);
    })
  }

  const onSubmit = async () => {
    try {
      setLoading(true);
      const generateBarCodes = await axios.post(`/generate-pdf`, {
        barcodeData: barcodeData,
        fileName: FileName,
        layout: layout
      });
      if (generateBarCodes) {
        console.log("onSubmit after call=====>", generateBarCodes);
        let airTableRecordID = generateBarCodes.data.response.id
        form.resetFields();
        console.log("onSubmit airTableRecordID======>", airTableRecordID);
        myInterval = setInterval(() => {
          getPdfRecord({ recordId: airTableRecordID })
        }, 5000);
        setMsg({
          status: true,
          message: generateBarCodes.data.msg,
          type: 'warning'
        })
      }
    } catch (error) {
      setLoading(false);
      setMsg({
        status: true,
        message: error.message,
        type: 'error'
      })
      setTimeout(() => {
        setMsg({
          status: false,
          message: '',
          type: ''
        })
      }, 3000);
    }
  };

  usePage('Dashboard');
  return (
    <>
      <Wrapper>
        <Form
          form={form}
          size="large"
          name="form"
          initialValues={{ remember: true }}
          onFinish={onSubmit}
          layout={'vertical'}
          autoComplete="on"
        >
          <Heading style={{ textAlign: 'center', marginBottom: '25px' }}>
            Generate Bar Code
          </Heading>
          <Form.Item name="layout" style={{ textAlign: 'center' }}>
            <Heading name="layoutHeading" size="20px" weight="normal" style={{ marginBottom: '15px' }}>
              Layout Selection
            </Heading>
            <Radio.Group name='layouts' value={layout} onChange={onChangeLayout} buttonStyle="solid">
              <Radio.Button style={{ width: "10rem" }} name="Coppelmans" value={1}>Coppelmans</Radio.Button>
              <Radio.Button style={{ width: "10rem" }} name="GMF" value={2} >GMF</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Excel File"
            name="file"
            rules={[
              {
                required: true,
                message: 'File Not Supported',
              },
            ]}
          >
            <Input type={'file'} name="upload-file" onChange={onFinish} />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isLoading}
              style={{ width: '100%', marginTop: '10px' }}
              type="primary"
              htmlType="submit"
            >
              Generate Codes
            </Button>
          </Form.Item>
          <Form.Item>
            {msg.status && <Alert message={msg.message} type={msg.type} />}
          </Form.Item>
        </Form>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;

  form {
    width: 90%;
    max-width: 500px;

    label::before {
      display: none !important;
    }

    .ant-form-item-explain-error {
      margin-bottom: 12px;
    }

    .ant-form-item-label {
      padding-bottom: 3px;
      & > label {
        font-size: 16px;
      }
    }
  }
`;
