import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { Provider } from "react-redux"
import store from "redux/store"
import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router } from "react-router-dom"

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App /></Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

serviceWorker.unregister();