import {useState} from 'react';

import {Form, Input, Button, message} from 'antd';

import styled from 'styled-components';

import {useHistory} from 'react-router-dom';
import axios from 'axios';

export default function Login() {
  const [isLoading, setLoading] = useState(false);
  // const [isNotVerified, setNotVerified] = useState(false);
  let isNotVerified = false
  // const user = JSON.parse(localStorage.getItem('loggedInUser'));

  // if(user!==null || user!==undefined){
  //   redirectTo('/');
  // }
  const {push: redirectTo} = useHistory();

  const onFinish = async (values) => {
    setLoading(true);
    const loginUser = await axios.post('/login', values);
    let res = loginUser.data;
    if (res.msg === 'User Authenticated') {
      let d = JSON.stringify(res.data);
      if (res.data.role === 'admin') {
        localStorage.setItem('loggedInUser', d);
        localStorage.setItem('loggedInUserRole', 'admin');
        redirectTo('/dashboard');
      }
    } else if (res.status === 201) {
      message.error('You must be a valid user to continue...');
    } else {
      message.error('Internal Server Error');
    }
    setLoading(false);
  };

  if (isNotVerified)
    return (
      <></>
      // <Verification
      //   emailAddress={userEmail.current}
      //   sendCodeAuto={true}
      //   onSuccess={() => (window.location.href = "/vouchers")}
      // />
    );
  else
    return (
      <>
        <div style={{position: 'absolute', right: '20px', top: '20px'}}></div>
        <Wrapper>
          <Form
            size="large"
            name="userLogin"
            initialValues={{remember: true}}
            onFinish={onFinish}
            layout={'vertical'}
            autoComplete="on"
          >
            <div style={{textAlign: 'center', paddingBottom: '20px'}}>
              <img width={120} src="/logo.png" alt="lundager" />
            </div>
            {/* <Heading style={{textAlign: 'center', marginBottom: '25px'}}>
              Sign In
            </Heading> */}
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Email',
                },
                {
                  pattern:
                    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid Email Address',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Password',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                loading={isLoading}
                style={{width: '100%', marginTop: '10px'}}
                type="primary"
                htmlType="submit"
              >
                Sign In
              </Button>
            </Form.Item>
          </Form>
        </Wrapper>
        <div
          style={{
            position: 'fixed',
            bottom: '0',
            padding: '20px',
            width: '100%',
          }}
        ></div>
      </>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 90%;
    max-width: 500px;

    label::before {
      display: none !important;
    }

    .ant-form-item-explain-error {
      margin-bottom: 12px;
    }

    .ant-form-item-label {
      padding-bottom: 3px;
      & > label {
        font-size: 16px;
      }
    }
  }
`;
